<template>
    <v-snackbar
        v-model="infoBarStore.state"
        :timeout="infoBarStore.timeout"
        :color="infoBarStore.color"
        location="top"
    >
        {{ infoBarStore.text }}
        <template v-slot:actions>
            <v-btn variant="text" @click="infoBarStore.hide">X</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { useInfoBarStore } from "@/stores/infoBarStore";

export default {
    setup() {
        const infoBarStore = useInfoBarStore();
        return { infoBarStore };
    },
};
</script>
