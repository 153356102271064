<template>
    <v-card elevation="16">
        <p class="welcome">
            Willkommen bei
            <img class="logo" :src="logoSrc" alt="Ranvoice Logo" />
        </p>
        <p class="subtitle">simplified i<b>nvoic</b>ing.</p>
    </v-card>
</template>

<script>
import { useAppStore } from "@/stores/appStore";
import { computed } from "vue";

export default {
    setup() {
        const store = useAppStore();

        const logoSrc = computed(() =>
            store.theme === "light"
                ? require("@/assets/ranvoice_logo_nosub_b_landscape.png")
                : require("@/assets/ranvoice_logo_nosub_w_landscape.png")
        );

        return {
            logoSrc,
        };
    },
};
</script>

<style scoped>
.welcome {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 4rem;
    font-weight: 200;
    line-height: 1.27;
    margin-top: 60px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.logo {
    height: 1.34em;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px;
}

@media (max-width: 400px) {
    .welcome {
        margin-top: 20px;
        font-size: 2.2rem;
    }
}

.subtitle {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 40px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>
