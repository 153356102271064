<template>
    <v-app-bar>
        <v-app-bar-nav-icon
            variant="text"
            @click.stop="store.drawer = !store.drawer"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>
            <router-link to="/home" class="navbar-logo-wrapper">
                <img
                    :src="store.logoSrc"
                    alt="ranvoice Logo"
                    class="navbar-logo"
                />
            </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :prepend-icon="themeToggleIcon" @click="toggleTheme"></v-btn>
        <v-btn prepend-icon="mdi-test-tube" @click="test"></v-btn>
    </v-app-bar>
</template>

<script>
import { computed } from "vue";
import { useAppStore } from "@/stores/appStore";

export default {
    setup(props, { emit }) {
        const store = useAppStore();

        const themeToggleIcon = computed(() =>
            store.theme === "light" ? "mdi-weather-night" : "mdi-weather-sunny"
        );

        const toggleTheme = () => {
            store.toggleTheme();
        };

        const test = () => {
            emit("testEvent", "Test button clicked");
        };

        return { store, themeToggleIcon, toggleTheme, test };
    },
};
</script>

<style scoped>
.navbar-logo-wrapper {
    display: inline-block;
}

.navbar-logo {
    height: 100px;
    margin-top: 10px;
    cursor: pointer;
}
</style>
