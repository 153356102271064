import { defineStore } from "pinia";
import { login, logout, resetPassword } from "@/services/authService";

export const useAppStore = defineStore("app", {
    state: () => ({
        theme: "light",
        drawer: false,
        userId: null,
    }),

    getters: {
        isDarkTheme: (state) => state.theme === "dark",
        logoSrc: (state) =>
            state.theme === "light"
                ? new URL("@/assets/ranvoice_logo_b.png", import.meta.url).href
                : new URL("@/assets/ranvoice_logo_w.png", import.meta.url).href,
        isLoggedIn: (state) => state.userId !== null,
    },

    actions: {
        toggleTheme() {
            this.theme = this.theme === "light" ? "dark" : "light";
        },
        setDrawer(state) {
            this.drawer = state;
        },

        async login({ username, password }) {
            try {
                const data = await login({ username, password });
                console.log("appStore: logged in");
                if (data.success) {
                    this.userId = data.userId;
                    console.log("appStore: logged in");
                } else {
                    throw new Error(data.message || "Login failed.");
                }
            } catch (error) {
                console.error("login error:", error);
                throw error;
            }
        },

        async logout() {
            try {
                const data = await logout();

                if (data.success) {
                    this.userId = null;
                    console.log("appStore: logged out.");
                } else {
                    throw new Error(data.message || "Logout failed.");
                }
            } catch (error) {
                console.error("logout error:", error);
                throw error;
            }
        },

        async resetPassword({ email }) {
            try {
                const data = await resetPassword(email);
                if (data.success) {
                    console.log("appStore: password reset link sent");
                } else {
                    throw new Error(data.message || "resetPassword failed.");
                }
            } catch (error) {
                console.error("appStore: resetPassword error:", error);
                throw error;
            }
        },
    },
    persist: true,
});
