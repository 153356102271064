<template>
    <h1>{{ title }}</h1>
    <p>{{ subtitle }}</p>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String,
    },
};
</script>

<style scoped>
h1,
p {
    font-weight: 300;
}

p {
    margin-bottom: 1.2rem;
    border-bottom: 1px solid #ccc; /* Fine line below the paragraph */
    padding-bottom: 0.5rem; /* Optional: Adds spacing between text and the line */
}
</style>
