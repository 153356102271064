<template>
    <div class="pa-4 text-center">
        <v-dialog v-model="dialog" max-width="600">
            <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                    class="text-none font-weight-regular"
                    text="Passwort vergessen?"
                    color="primary"
                    variant="text"
                    v-bind="activatorProps"
                ></v-btn>
            </template>

            <v-card title="Passwort zurücksetzen">
                <v-card-text>
                    <p class="mb-4">
                        Hast du dein Passwort vergessen? Gib deine E-mail ein
                        und wir senden dir in den nächsten Minuten einen Link
                        zu, mit welchem du ein neues Passwort definieren kannst.
                    </p>

                    <v-text-field
                        v-model="email"
                        label="E-mail"
                        required
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text="Schliessen"
                        variant="plain"
                        @click="dialog = false"
                    ></v-btn>

                    <v-btn
                        color="primary"
                        text="Senden"
                        variant="tonal"
                        @click="reset"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { useAppStore } from "@/stores/appStore";
import { useInfoBarStore } from "@/stores/infoBarStore";

export default {
    data: () => ({
        dialog: false,
        email: "",
    }),
    methods: {
        async reset() {
            const store = useAppStore();
            const infoBar = useInfoBarStore();

            try {
                await store.resetPassword({ email: this.email });
                this.dialog = false;
                infoBar.show(
                    "success",
                    "Link wurde versendet. Checke deine Emails und befolge die Anweisungen.",
                    true
                );
            } catch (error) {
                console.log(error);
                if (error.response?.data?.message === "Email not found") {
                    infoBar.show(
                        "error",
                        "Link konnte nicht gesendet werden: E-mail nicht gefunden",
                        true
                    );
                } else {
                    infoBar.show(
                        "error",
                        "Link konnte nicht gesendet werden: " +
                            error.response?.data?.message,
                        true
                    );
                }
            }
        },
    },
};
</script>
