import { useRouter } from "vue-router";
import { useAppStore } from "@/stores/appStore";
import { useInfoBarStore } from "@/stores/infoBarStore";

export function useSessionTimeout() {
    const store = useAppStore();
    const router = useRouter();
    const infoBar = useInfoBarStore();

    let timeoutId = null;
    const timeoutDuration = 30 * 60 * 1000; // 6 seconds for testing, increase as needed

    const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(async () => {
            console.log("Session expired due to inactivity");
            await store.logout();
            console.log(1);
            router.push("/"); // Redirect to login page
            infoBar.show(
                "info",
                "Du wurdest wegen Inaktivität aus Sicherheitsgründen automatisch abgemeldet.",
                false
            );
        }, timeoutDuration);
    };

    // Start timeout detection
    const startSessionTimeout = () => {
        window.addEventListener("mousemove", resetTimeout);
        window.addEventListener("keydown", resetTimeout);
        resetTimeout();
    };

    // Stop timeout detection
    const stopSessionTimeout = () => {
        window.removeEventListener("mousemove", resetTimeout);
        window.removeEventListener("keydown", resetTimeout);
        clearTimeout(timeoutId);
    };

    return {
        startSessionTimeout,
        stopSessionTimeout,
    };
}
