import axios from "axios";

const API_BASE_URL = "https://api.ranvoice.ch";

export const login = async ({ username, password }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, {
            username,
            password,
        });
        return response.data;
    } catch (error) {
        console.error("API login error:", error);
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axios.post(`${API_BASE_URL}/logout`);
        return response.data;
    } catch (error) {
        console.error("API logout error:", error);
        throw error;
    }
};

export const resetPassword = async (
    email,
    newPassword = null,
    username = null
) => {
    try {
        if (newPassword !== null && username !== null) {
            const step = "set-new-password";
            const response = await axios.post(
                `${API_BASE_URL}/reset-password`,
                {
                    step,
                    email,
                    newPassword,
                }
            );
            return response.data;
        } else {
            const step = "send-link";
            const response = await axios.post(
                `${API_BASE_URL}/reset-password`,
                {
                    step,
                    email,
                }
            );
            return response.data;
        }
    } catch (error) {
        console.error("API resetPassword error:", error);
        throw error;
    }
};
