<template>
    <InfoBar />
    <v-app :theme="theme">
        <AppBarComponent @testEvent="handleTestEvent" />
        <NavigationDrawer />
        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>
        <FooterComponent />
    </v-app>
</template>

<script>
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import InfoBar from "@/components/InfoBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import AppBarComponent from "@/components/AppBarComponent.vue";

import { useAppStore } from "@/stores/appStore";
import { onBeforeUnmount, watch, computed } from "vue";
import { useSessionTimeout } from "@/utils/sessionTimeout";
import { useInfoBarStore } from "./stores/infoBarStore";

export default {
    components: {
        NavigationDrawer,
        InfoBar,
        FooterComponent,
        AppBarComponent,
    },
    setup() {
        const store = useAppStore();
        const infoBar = useInfoBarStore();
        const { startSessionTimeout, stopSessionTimeout } = useSessionTimeout();

        onBeforeUnmount(() => {
            stopSessionTimeout();
        });

        const theme = computed(() => store.theme);
        const isLoggedIn = computed(() => store.isLoggedIn);

        watch(isLoggedIn, (state) => {
            state ? startSessionTimeout() : stopSessionTimeout();
        });

        const handleTestEvent = (message) => {
            console.log(message);
            infoBar.show("info", "Test Knopf gedrückt!", false);
        };

        return { theme, handleTestEvent };
    },
};
</script>
