<template>
    <v-footer :class="[background, 'fixed-footer']">
        <v-row justify="center">
            <v-btn
                v-for="link in links"
                :key="link"
                class="mx-2"
                rounded="xl"
                variant="text"
            >
                {{ link }}
            </v-btn>
            <v-col class="text-center mt-4 small-text" cols="12">
                Copyright © {{ new Date().getFullYear() }} by Raphael Naegeli.
                All Rights Reserved.
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import { useAppStore } from "@/stores/appStore";

export default {
    data: () => ({
        links: ["Über ranvoice", "Impressum", "Datenschutz", "Kontakt"],
    }),
    computed: {
        background() {
            const store = useAppStore();
            return store.theme === "dark"
                ? "bg-dark-grey"
                : "bg-grey-lighten-3";
        },
    },
};
</script>

<style scoped>
.fixed-footer {
    height: 100px; /* Fixed height */
    min-height: 100px; /* Ensures the height is not smaller */
    max-height: 200px; /* Ensures the height doesn't grow */
    display: flex; /* Flexbox layout to ensure proper alignment */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    overflow: hidden;
}

.small-text {
    font-size: 0.75rem;
}
</style>
