<template>
    <v-container>
        <HeaderComponent
            :title="'Anmelden'"
            :subtitle="'Melde dich mit deinen Anmeldeinformationen an'"
        />
        <v-form ref="form" @submit.prevent="submitLogin" v-model="formValid">
            <v-text-field
                v-model="username"
                autocomplete="username"
                label="Benutzername"
                :rules="[rules.required]"
                required
            />
            <v-text-field
                v-model="password"
                autocomplete="current-password"
                label="Passwort"
                type="password"
                :rules="[rules.required]"
                required
            />
            <v-btn
                class="mt-4"
                type="submit"
                block
                :disabled="!formValid || loading"
                @click="submitLogin"
            >
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="24"
                    color="white"
                />
                <span v-else>Login</span>
            </v-btn>
        </v-form>
        <PasswordResetDialog />
    </v-container>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from "@/stores/appStore";
import { useInfoBarStore } from "@/stores/infoBarStore";
import HeaderComponent from "@/components/HeaderComponent.vue";
import PasswordResetDialog from "@/components/PasswordResetDialog.vue";

export default {
    components: {
        HeaderComponent,
        PasswordResetDialog,
    },
    setup() {
        const appStore = useAppStore();
        const router = useRouter();
        const infoBar = useInfoBarStore();

        const username = ref("");
        const password = ref("");
        const loading = ref(false);
        const formValid = ref(false);

        const rules = {
            required: (value) => !!value || "Required.",
        };

        const submitLogin = async () => {
            loading.value = true;

            try {
                await appStore.login({
                    username: username.value,
                    password: password.value,
                });
                console.log("heeeere");
                console.log(2);
                router.push("/home");
                infoBar.show("success", "Login erfolgreich", true);
            } catch (error) {
                console.log(error.response?.data?.message);
                if (error.response?.data?.message === "Invalid credentials.") {
                    infoBar.show(
                        "error",
                        "Login fehlgeschlagen: Benutzer nicht gefunden oder falsches Passwort.",
                        true
                    );
                }
            } finally {
                loading.value = false;
            }
        };

        return {
            username,
            password,
            loading,
            rules,
            submitLogin,
            formValid,
        };
    },
};
</script>

<style scoped></style>
