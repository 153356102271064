// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
    icons: {
        defaultSet: "mdi", // Use the MDI font set
    },
    theme: {
        defaultTheme: "dark",
    },
});
