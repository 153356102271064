import { defineStore } from "pinia";

export const useInfoBarStore = defineStore("infoBar", {
    state: () => ({
        state: false,
        color: "success",
        text: "",
        timeout: 5000,
    }),
    actions: {
        show(type, text, withTimeout = true) {
            this.color = type;
            this.text = text;
            withTimeout ? (this.timeout = 5000) : (this.timeout = -1);
            this.state = true;
        },
        hide() {
            this.state = false;
        },
    },
});
