<template>
    <v-navigation-drawer
        :width="280"
        :location="$vuetify.display.mobile ? 'bottom' : 'left'"
        temporary
        v-model="store.drawer"
    >
        <v-list v-if="store.isLoggedIn">
            <v-list-item
                prepend-icon="mdi-home"
                title="Home"
                to="/"
            ></v-list-item>
            <v-list-item
                prepend-icon="mdi-account-multiple"
                title="Kontakte"
            ></v-list-item>
            <v-list-item
                prepend-icon="mdi-format-list-checks"
                title="Debitoren"
            ></v-list-item>
            <v-list-group value="Rechnungen">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        prepend-icon="mdi-invoice-text-outline"
                        title="Rechnungen"
                    ></v-list-item>
                </template>
                <v-list-item
                    title="Einzelrechnung"
                    prepend-icon="mdi-invoice-text-outline"
                ></v-list-item>
                <v-list-item
                    title="Rechnungsserie"
                    prepend-icon="mdi-invoice-text-multiple-outline"
                ></v-list-item>
                <v-list-item
                    title="Templates"
                    prepend-icon="mdi-newspaper-variant-outline"
                ></v-list-item>
                <v-list-item
                    title="Rechnungsarchiv"
                    prepend-icon="mdi-archive-outline"
                ></v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <v-list-group value="Profil">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        prepend-icon="mdi-account"
                        title="Profil"
                    ></v-list-item>
                </template>
                <v-list-item
                    title="Benutzerprofil"
                    prepend-icon="mdi-card-account-mail-outline"
                ></v-list-item>
                <v-list-item
                    title="Einstellungen"
                    prepend-icon="mdi-cog-outline"
                ></v-list-item>
                <v-list-item
                    title="Anleitungen"
                    prepend-icon="mdi-book-outline"
                ></v-list-item>
            </v-list-group>
            <v-list-item
                title="Abmelden"
                prepend-icon="mdi-logout"
                @click="logout"
            ></v-list-item>
        </v-list>
        <v-list v-else>
            <v-list-item
                prepend-icon="mdi-login"
                title="Anmelden"
                to="/user/login"
            ></v-list-item>
            <v-list-item
                prepend-icon="mdi-account-plus"
                title="Registrieren"
                to="/"
            ></v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { useAppStore } from "@/stores/appStore";
import { useInfoBarStore } from "@/stores/infoBarStore";
import { useRouter } from "vue-router";

export default {
    setup() {
        const store = useAppStore();
        const router = useRouter();
        const infoBar = useInfoBarStore();

        const logout = async () => {
            await store.logout();
            console.log(3);
            router.push("/");
            infoBar.show("success", "Du wurdest erfolgreich abgemeldet", true);
        };

        return { store, logout };
    },
};
</script>
