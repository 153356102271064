import { createRouter, createWebHistory } from "vue-router";
import { useAppStore } from "@/stores/appStore";
import WelcomeView from "@/views/WelcomeView.vue";
import LoginView from "../views/user/LoginView.vue";
import HomeView from "@/views/HomeView.vue";
// import RegisterView from '../views/user/RegisterView.vue';
// import ProfileView from '../views/user/ProfileView.vue';
// import ContactsView from '../views/contacts/ContactsView.vue';

const routes = [
    { path: "/", name: "Welcome", component: WelcomeView },
    { path: "/user/login", name: "Login", component: LoginView },
    { path: "/home", name: "Home", component: HomeView },
    // {
    //   path: '/user/register',
    //   name: 'Register',
    //   component: RegisterView
    // },
    // {
    //   path: '/user/profile',
    //   name: 'Profile',
    //   component: ProfileView
    // },
    // {
    //   path: '/contacts',
    //   name: 'Contacts',
    //   component: ContactsView
    // }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const store = useAppStore();
    console.log(
        "Routing from: " + from.path + " to " + to.path,
        "isLoggedIn:",
        store.isLoggedIn
    );
    if (to.path === "/" && store.isLoggedIn) {
        // Redirect logged-in users accessing "/" to "/home"
        next("/home");
    } else if (to.path === "/home" && !store.isLoggedIn) {
        // Allow navigation to other routes if the user is not logged in
        next("/");
    } else {
        // Proceed to the intended route
        next();
    }
});

export default router;
